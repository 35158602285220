import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'app/auth/service/authentication.service';

import { environment } from 'environments/environment';


@Component({
  selector: 'app-auth-login-v2',
  templateUrl: './auth-login-v2.component.html',
  styleUrls: ['./auth-login-v2.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthLoginV2Component implements OnInit {
  //  Public
  public coreConfig: any;
  public loginForm: UntypedFormGroup;
  public loading = false;
  public submitted = false;
  public returnUrl: string;
  public error = '';
  public passwordTextType: boolean;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   */
  constructor(
    private _coreConfigService: CoreConfigService,
    private _formBuilder: UntypedFormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private service:AuthenticationService,
    private http: HttpClient,
  ) {
    this._unsubscribeAll = new Subject();

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  /**
   * Toggle password
   */
  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  onSubmit() {
    this.submitted = true;
   

    // this.loginForm.value
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      console.log("invalid")
    }
    else{
    // Login
    // this.loading = true;
    // Send HTTP POST request to your API

    console.log(this.loginForm.value);
   const email=this.loginForm.value['email']
    const pass=this.loginForm.value['password'];
    console.log(email);
    this.http.post<any>(`${environment.apiUrl}/Login`, this.loginForm.value)
    .subscribe({
      next: response => {
        console.log('Response:', response);
        if (response.status === 'Ok') {
          console.log('Login successful');
          // Assuming you have received the username and password in the response
          const username = response.username;
          const password = response.password;
          localStorage.setItem('username', username);
          // this.service.login(username,password);
          // Optionally, you can store the username and password in localStorage or a service
     

          // // Redirect to the shipments page
          this._router.navigate(['/shipments']);
        } else {
          console.error('Login failed:', response.message);
          this.error = 'Invalid credentials. Please try again.';
        }
      },
      error: error => {
        // Handle error response
        console.error('Error:', error);
        this.error = 'Invalid credentials. Please try again.';
      }
    });
   
  }
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.loginForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';

    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
