<div class="content-wrapper container-xxl p-0">
    <!-- <nav aria-label="breadcrumb">
        <ol class="breadcrumb" style="font-size: 1.5rem; padding: 10px;">
            <li class="breadcrumb-item"><a href="/home">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Inwards</li>
        </ol>
    </nav> -->
    <div class="card mt-1 container">
      
        <div class="d-flex align-items-center">
            <h4 class='mt-1 mr-2'>Inventory - Pune</h4>
            <i class="fa fa-file-excel-o ml-1 cursor-pointer align-items-right justify-content-end " style="font-size: 20px; color: green;" (click)="exportAsXLSX()"></i>
        </div>
        
        <!-- <div class="cardbody ml-3 mt-1"> -->

        <div class="cardbody mt-1">
            <div class="row">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <form [formGroup]="InwardsForm" >
                                <div class="form-row">
                                    <div class="col-md-4 mb-2">
                                        <label for="etaDate">FROM</label>
                                        <div class="input-group">
                                            <input type="date" formControlName="etaDate" id="etaDate" class="form-control form-control-sm">
                                        </div>
                                    </div>
                                    <div class="col-md-4 mb-2">
                                        <label for="etdDate">TO</label>
                                        <div class="input-group">
                                            <input type="date" formControlName="etdDate" id="etdDate" class="form-control form-control-sm">
                                        </div>
                                    </div>
                                    <div class="form-group mt-2 ">
                                        <button type="button" class="btn btn-primary" (click)="collectFormData()">View</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>                
            </div>
            <section id="ngx-datatable-kitchen-sink">
                <core-card-snippet [snippetCode]="_snippetCodeKitchenSink">
                <ngx-datatable [rows]="kitchenSinkRows" [rowHeight]="58" class="bootstrap core-bootstrap" [limit]="50"
            [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
            [selectionType]="SelectionType.checkbox" [limit]="basicSelectedOption" (select)="onSelect($event)">
                    <ngx-datatable-column [width]="50" [sortable]="false" [canAutoResize]="false" [draggable]="false"
                        [resizeable]="false">
                        <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
                            let-selectFn="selectFn">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" [checked]="allRowsSelected"
                                    (change)="selectFn(!allRowsSelected)" id="headerChkbxRef" />
                                <label class="custom-control-label" for="headerChkbxRef"></label>
                            </div>
                        </ng-template>
                        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value"
                            let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" [checked]="isSelected"
                                    (change)="onCheckboxChangeFn($event)" id="rowChkbxRef{{ rowIndex }}" />
                                <label class="custom-control-label" for="rowChkbxRef{{ rowIndex }}"></label>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="MASTER INVOICE" prop="masterInvoiceNumber" [width]="100"></ngx-datatable-column>
                    <ngx-datatable-column name="Invoice Date" prop="invoiceDate" [width]="120">
                        <ng-template let-value="value" ngx-datatable-cell-template>{{value | date:'dd-MM-yyyy'}}</ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Container" prop="containerNumber" [width]="150"></ngx-datatable-column>
                    <ngx-datatable-column name="RECEIPT DATE" prop="receiptDate" [width]="150">
                        <ng-template let-value="value" ngx-datatable-cell-template>{{value | date:'dd-MM-yyyy'}}</ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="PRODUCT_SKU" prop="productSKU" [width]="200"></ngx-datatable-column>
                    <ngx-datatable-column name="DESCRIPTION" prop="description" [width]="150"></ngx-datatable-column>
                    <ngx-datatable-column name="NO. OF PALLETS" prop="numberOfPallets" [width]="100"></ngx-datatable-column>
                    <ngx-datatable-column name="NO. OF PIECES" prop="numberOfPieces" [width]="100"></ngx-datatable-column>
                </ngx-datatable>
            </core-card-snippet>
            </section>
            <!-- {{rows | json}} -->
        </div>
    </div>
</div>