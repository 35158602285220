import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';
import { coreConfig } from 'app/app-config';
import { CardSnippetModule } from "../@core/components/card-snippet/card-snippet.module";

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { ShipmentsComponent } from './shipments/shipments.component';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { AgingDUISBURGComponent } from './aging-duisburg/aging-duisburg.component';
import { AgingGrangemouthComponent } from './aging-grangemouth/aging-grangemouth.component';
import { InventoryDuisburgComponent } from './inventory/inventory-duisburg/inventory-duisburg.component';
import { InventoryGrangemouthComponent } from './inventory/inventory-grangemouth/inventory-grangemouth.component';
import { OutwardDuisburgComponent } from './outward/outward-duisburg/outward-duisburg.component';
import { OutwardGrangemouthComponent } from './outward/outward-grangemouth/outward-grangemouth.component';
import { AgingPuneComponent } from './aging-pune/aging-pune.component';
import { InwardsPuneComponent } from './inwards-pune/inwards-pune.component';
import { InwardsDUISBURGComponent } from './inwards-duisburg/inwards-duisburg.component';
import { InwardsGrangemouthComponent } from './inwards-grangemouth/inwards-grangemouth.component';
import { InventoryPuneComponent } from './inventory-pune/inventory-pune.component';
import { ShipmentsGrangemouthComponent } from './shipments-grangemouth/shipments-grangemouth.component';
import { ShipmentsPuneComponent } from './shipments-pune/shipments-pune.component';
import { OutwardPuneComponent } from './outward/outward-pune/outward-pune.component';



const appRoutes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
  }
];

@NgModule({
  declarations: [
    AppComponent, 
    ShipmentsComponent,
    AgingDUISBURGComponent,
    AgingGrangemouthComponent,
    InventoryDuisburgComponent,
    InventoryGrangemouthComponent,
    OutwardDuisburgComponent,
    OutwardGrangemouthComponent,
    AgingPuneComponent,
    InwardsPuneComponent,
    InwardsDUISBURGComponent,
    InwardsGrangemouthComponent,
    InventoryPuneComponent,
    ShipmentsGrangemouthComponent,
    ShipmentsPuneComponent,
    OutwardPuneComponent
    
    ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgSelectModule,
    
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy'
    }),
    TranslateModule.forRoot(),

    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),
    NgxDatatableModule,
    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,
    
    // App modules
    LayoutModule,
    SampleModule
  ],
  providers: [DatePipe,
   
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule {}
