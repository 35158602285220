<div class="card mt-11 container">
      
    <div class="d-flex align-items-center">
        <h4 class='mt-1 mr-2'>Shipments - GRUNGEMOUTH</h4>
        <i class="fa fa-file-excel-o ml-1 cursor-pointer align-items-right justify-content-end " style="font-size: 20px; color: green;" (click)="exportAsXLSX()"></i>
    </div>
    
    <!-- <div class="cardbody ml-3 mt-1"> -->

    <div class="cardbody mt-1">
        <div class="row">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <form [formGroup]="ShipmentsForm" >
                            <div class="form-row">
                                <div class="col-md-4 mb-2">
                                    <label for="etaDate"> FROM</label>
                                    <div class="input-group">
                                        <input type="date" formControlName="etaDate" id="etaDate" class="form-control form-control-sm">
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2">
                                    <label for="etdDate"> TO</label>
                                    <div class="input-group">
                                        <input type="date" formControlName="etdDate" id="etdDate" class="form-control form-control-sm">
                                    </div>
                                </div>
                                <div class="col-md-4 mt-2 ">
                                    <button type="button" class="btn btn-primary btn-sm" (click)="collectFormData()">View</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>                
        </div>
        <section id="ngx-datatable-kitchen-sink">
            <core-card-snippet [snippetCode]="_snippetCodeKitchenSink">
            <ngx-datatable [rows]="kitchenSinkRows" [rowHeight]="58" class="bootstrap core-bootstrap" [limit]="50"
        [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
        [selectionType]="SelectionType.checkbox" [limit]="basicSelectedOption" (select)="onSelect($event)">
                <ngx-datatable-column [width]="50" [sortable]="false" [canAutoResize]="false" [draggable]="false"
                    [resizeable]="false">
                    <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
                        let-selectFn="selectFn">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" [checked]="allRowsSelected"
                                (change)="selectFn(!allRowsSelected)" id="headerChkbxRef" />
                            <label class="custom-control-label" for="headerChkbxRef"></label>
                        </div>
                    </ng-template>
                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value"
                        let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" [checked]="isSelected"
                                (change)="onCheckboxChangeFn($event)" id="rowChkbxRef{{ rowIndex }}" />
                            <label class="custom-control-label" for="rowChkbxRef{{ rowIndex }}"></label>
                        </div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Invoice" prop="invoiceNumber" [width]="100"></ngx-datatable-column>
                <ngx-datatable-column name="Invoice Date" prop="invoiceDate" [width]="120">
                    <ng-template let-value="value" ngx-datatable-cell-template>{{value | date:'dd-MM-yyyy'}}</ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="HBL" prop="houseNo" [width]="150"></ngx-datatable-column>
                <ngx-datatable-column name="Container" prop="containerNo" [width]="150"></ngx-datatable-column>
                <ngx-datatable-column name="POL" prop="pol" [width]="350"></ngx-datatable-column>
                <ngx-datatable-column name="POD" prop="pod" [width]="200"></ngx-datatable-column>
                <ngx-datatable-column name="Final Destination" prop="finalDestination" [width]="150"></ngx-datatable-column>
                <ngx-datatable-column name="ETD POL" prop="etd" [width]="100">
                    <ng-template let-value="value" ngx-datatable-cell-template>{{value | date:'dd-MM-yyyy'}}</ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="ETA POD" prop="eta" [width]="100">
                    <ng-template let-value="value" ngx-datatable-cell-template>{{value | date:'dd-MM-yyyy'}}</ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="ETA Warehouse" prop="etaWarehouse" [width]="140">
                    <ng-template let-value="value" ngx-datatable-cell-template>{{value | date:'dd-MM-yyyy'}}</ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Commodity Description" prop="commodityDescription" [width]="200"></ngx-datatable-column>
                <ngx-datatable-column name="Status / Remarks" prop="remarks" [width]="100"></ngx-datatable-column>
            </ngx-datatable>
        </core-card-snippet>
        </section>
        <!-- {{rows | json}} -->
    </div>
</div>
