
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import * as snippet from 'app/Table/datatables.snippetcode'
import { CoreTranslationService } from '@core/services/translation.service';
import { ColumnMode,id, SelectionType } from '@swimlane/ngx-datatable';
import Swal from 'sweetalert2';
import { NgSelectConfig, NgSelectModule } from '@ng-select/ng-select';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ExportExeclFileService } from 'export-execl-file.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-inventory-pune',
  templateUrl: './inventory-pune.component.html',
  styleUrls: ['./inventory-pune.component.scss']
})
export class InventoryPuneComponent implements OnInit {

  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('tableRowDetails') tableRowDetails: any;

  // snippet code variables
  public _snippetCodeKitchenSink = snippet.snippetCodeKitchenSink;
  public _snippetCodeInlineEditing = snippet.snippetCodeInlineEditing;
  public _snippetCodeRowDetails = snippet.snippetCodeRowDetails;
  public _snippetCodeCustomCheckbox = snippet.snippetCodeCustomCheckbox;
  public _snippetCodeResponsive = snippet.snippetCodeResponsive;
  public _snippetCodeMultilangual = snippet.snippetCodeMultilangual;
  public _snippetCodeVertical = snippet.snippetCodeVertical;

  private tempData = [];
  public contentHeader: object;
  public rows: any = [];
  public companyType = [];
  public myType = [];
  public selected = [];
  public kitchenSinkRows: any;
  public basicSelectedOption: number = 10;
  public ColumnMode = ColumnMode;
  public expanded = {};
  offset: number = 0;
  totalItems: number; 
  public chkBoxSelected = [];
  public SelectionType = SelectionType;
  InwardsForm: FormGroup;
  currentDate = new Date();
  formattedDate = this.currentDate.toISOString().slice(0,10); // Get YYYY-MM-DD format
  titleWithDate = `Inwards - Pune (${this.formattedDate})`;
   /**
   * Method Search (filter)
   *
   * @param event
   */
   filterUpdate(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.tempData.filter(function (d) {
     console.log(d.formattedAddress);
      return d.addressTypeNick.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.kitchenSinkRows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  } 

  /**
  * Row Details Toggle
  *
  * @param row
  */
 rowDetailsToggleExpand(row) {
   this.tableRowDetails.rowDetail.toggleExpandRow(row);
 }


 onSelect({ selected }) {
   console.log('Select Event', selected, this.selected);

   this.selected.splice(0, this.selected.length);
   this.selected.push(...selected);
 }
  /**
   * For ref only, log selected values
   *
   * @param selected
   */
  // onSelect(myType) {
  //   console.log(myType.target.value);
  // }

  /**
   * For ref only, log activate events
   *
   * @param selected
   */
/**
   * Custom Chkbox On Select
   *
   * @param { selected }
   */
 /**
   * Constructor
   *
   * @param {DatatablesService} _datatablesService
   * @param {CoreTranslationService} _coreTranslationService
   */

  headers = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private _coreTranslationService: CoreTranslationService, private httpclient: HttpClient, private config: NgSelectConfig,private excelExport:ExportExeclFileService, private fb: FormBuilder, public datepipe:DatePipe,  private modalService: NgbModal) {
    this.InwardsForm = this.fb.group({
      etaDate:[''],
      etdDate:['']
    });
   }

  ngOnInit(): void {
  }


collectFormData() {
  const etaDate = this.InwardsForm.get('etaDate').value;
  const etdDate = this.InwardsForm.get('etdDate').value;
console.log("etaDate:",etaDate, "& etdDate:", etdDate);
  this.getDataTableRows(etaDate, etdDate);
}

  getDataTableRows(etaDate,etdDate) {
   
    return new Promise((resolve, reject) => {
        this.httpclient.get(`${environment.apiUrl}/Inward/InwardsPune?fromDate=${etaDate}&toDate=${etdDate}`).subscribe((response: any) => {
        // console.log("Inwards Response",response);
        this.rows = response;
        this.tempData = this.rows;
        this.kitchenSinkRows = this.rows;
        console.log(this.rows);
        JSON.stringify(this.rows)
        resolve(this.rows);
      }, reject);
    });
  }
 
  exportAsXLSX():void {
    this.excelExport.exportAsExcelFile(this.rows, this.titleWithDate);
  }

}
