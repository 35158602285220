<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- Welcome section start -->
    <section id="welcome-section">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">WELCOME TO FRETLOG</h4>
            </div>
            <div class="card-body pb-1">
              <p class="card-text">Welcome to Fretlog by WMS.</p>
              <!-- <p class="card-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sit amet tellus eget enim
                lobortis scelerisque ut ac ipsum. Integer id efficitur turpis. Nullam condimentum pretium
                mi, at faucibus sapien scelerisque nec. Aliquam erat volutpat. Ut accumsan risus nunc,
                quis sollicitudin enim vehicula non. In hac habitasse platea dictumst.
              </p>
              <p class="card-text">
                Fusce id magna facilisis, consectetur odio id, semper nisi. Sed euismod velit nec nisi
                vehicula bibendum. Phasellus tincidunt purus ac luctus malesuada. Nullam pulvinar orci ut
                tortor viverra, nec mollis neque luctus. Integer fringilla nunc at mauris malesuada, sed
                fermentum velit ultricies.
              </p> -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Welcome section end -->
  </div>
</div>
