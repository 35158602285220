import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CoreCommonModule } from '@core/common.module';

import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

import { SampleComponent } from './sample.component';
import { HomeComponent } from './home.component';
import { ShipmentsComponent } from 'app/shipments/shipments.component';
import { AgingDUISBURGComponent } from 'app/aging-duisburg/aging-duisburg.component';
import { AgingGrangemouthComponent } from 'app/aging-grangemouth/aging-grangemouth.component';
import { AuthGuard } from 'app/auth.guard';
import { AuthLoginV2Component } from '../pages/authentication/auth-login-v2/auth-login-v2.component';
import { InventoryDuisburgComponent } from 'app/inventory/inventory-duisburg/inventory-duisburg.component';
import { InventoryGrangemouthComponent } from 'app/inventory/inventory-grangemouth/inventory-grangemouth.component';
import { OutwardDuisburgComponent } from 'app/outward/outward-duisburg/outward-duisburg.component';
import { OutwardGrangemouthComponent } from 'app/outward/outward-grangemouth/outward-grangemouth.component';
import { ShipmentsGrangemouthComponent } from 'app/shipments-grangemouth/shipments-grangemouth.component';
import { ShipmentsPuneComponent } from 'app/shipments-pune/shipments-pune.component';
import { InwardsPuneComponent } from 'app/inwards-pune/inwards-pune.component';
import { InwardsGrangemouthComponent } from 'app/inwards-grangemouth/inwards-grangemouth.component';
import { InwardsDUISBURGComponent } from 'app/inwards-duisburg/inwards-duisburg.component';
import { AgingPuneComponent } from 'app/aging-pune/aging-pune.component';
import { InventoryPuneComponent } from 'app/inventory-pune/inventory-pune.component';
import { OutwardPuneComponent } from 'app/outward/outward-pune/outward-pune.component';

const routes = [
  {
    path: '',
    component: AuthLoginV2Component 
  },
  {
    path: 'sample',
    component: SampleComponent,
    data: { animation: 'sample' }
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard] ,
    data: { animation: 'home' }
  },
 
  {
    path: 'shipments',
    component: ShipmentsComponent,
    canActivate: [AuthGuard] ,
    data: { animation: 'shipments' }
  },
  {
    path: 'shipmentsGrungemouth',
    component: ShipmentsGrangemouthComponent,
    data: { animation: 'Shipments-Grungemouth' },
    canActivate: [AuthGuard]
  },
  {
    path: 'shipmentsPune',
    component: ShipmentsPuneComponent,
    data: { animation: 'Shipments-Pune' },
    canActivate: [AuthGuard]
  },
  {
    path: 'inwards',
    component: InwardsDUISBURGComponent,
    data: { animation: 'Inwards-DUISBURG' },
    canActivate: [AuthGuard]
  },
  {
    path: 'inwardsGrungemouth',
    component: InwardsGrangemouthComponent,
    data: { animation: 'Inwards-Grungemouth' },
    canActivate: [AuthGuard]
  },
  {
    path: 'inwardsPune',
    component: InwardsPuneComponent,
    data: { animation: 'Inwards-Pune' },
    canActivate: [AuthGuard]
  },

   {
    path: 'outwardReport',
    component: OutwardDuisburgComponent,
    data: { animation: 'outwardReport' },
    canActivate: [AuthGuard] ,
  },
  {
     path: 'outwardGrungemouth',
    component: OutwardGrangemouthComponent,
    data: { animation: 'outwardGrungemouth' },
    canActivate: [AuthGuard] ,
  },
  {
    path: 'outwardsPune',
   component: OutwardPuneComponent,
   data: { animation: 'outwardsPune' },
   canActivate: [AuthGuard] ,
 },
  {
    path: 'inventory',
    component: InventoryDuisburgComponent,
    data: { animation: 'Inventory' },
    canActivate: [AuthGuard] ,
  },
  {
    path: 'inventoryGrungemouth',
    component: InventoryGrangemouthComponent,
    data: { animation: 'inventoryGrungemouth' },
    canActivate: [AuthGuard] ,
  },
  {
    path: 'inventoryPune',
    component: InventoryPuneComponent,
    data: { animation: 'inventoryPune' },
    canActivate: [AuthGuard] ,
  },
  {
    path: 'aging',
    component: AgingDUISBURGComponent,
    data: { animation: 'aging' },
    canActivate: [AuthGuard] ,
  },
  {
    path: 'agingGrungemouth',
    component: AgingGrangemouthComponent,
    data: { animation: 'agingGrungemouth' },
    canActivate: [AuthGuard] ,
  },
  {
    path: 'agingPune',
    component: AgingPuneComponent,
    data: { animation: 'agingPune' },
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [SampleComponent, HomeComponent],
  imports: [RouterModule.forChild(routes), ContentHeaderModule, TranslateModule, CoreCommonModule],
  exports: [SampleComponent, HomeComponent]
})
export class SampleModule {}
