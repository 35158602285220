import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CoreCommonModule } from '@core/common.module';

import { ContentComponent } from 'app/layout/components/content/content.component';
import { VuexyloadingInterceptor } from 'app/vuexyloading.interceptor';
import { VuxySpinnerComponent } from 'app/vuxy-spinner/vuxy-spinner.component';

@NgModule({
  declarations: [ContentComponent,VuxySpinnerComponent],
  imports: [RouterModule, CoreCommonModule],
  providers:[ {
    provide: HTTP_INTERCEPTORS, useClass: VuexyloadingInterceptor, multi: true
  }],
  exports: [ContentComponent]
})
export class ContentModule {}
