import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  // {
  //   id: 'home',
  //   title: 'Home',
  //   translate: 'MENU.HOME',
  //   type: 'item',
  //   icon: 'home',
  //   url: 'home'
  // },
  // {
  //   id: 'sample',
  //   title: 'Sample',
  //   translate: 'MENU.SAMPLE',
  //   type: 'item',
  //   icon: 'file',
  //   url: 'sample'
  // },
  {
    id: 'shipments',
    type: 'section',
    title: 'shipments',
    translate: 'Shipments',
    icon: 'file',
    children: [
      {
        id: 'shipments',
        title: 'Shipments',
        translate: 'Shipments - DUISBURG',
        type: 'item',
        icon: 'list',
        url: 'shipments'
      },
      {
        id: 'Shipments - Grungemouth',
        title: 'Shipments - Grungemouth',
        translate: 'Shipments - GRUNGEMOUTH',
        type: 'item',
        icon: 'list',
        url: 'shipmentsGrungemouth'
      },
      {
        id: 'Shipments - Pune',
        title: 'Shipments - Pune',
        translate: 'Shipments - PUNE',
        type: 'item',
        icon: 'list',
        url: 'shipmentsPune'
      }
    ]
  },
  {
    id: 'Inwards',
    type: 'section',
    title: 'Inwards',
    translate: 'Inwards',
    icon: 'file',
    children: [
      {
        id: 'Inwards',
        title: 'Inwards',
        translate: 'Inwards - DUISBURG',
        type: 'item',
        icon: 'list',
        url: 'inwards'
      },
      {
        id: 'Inwards',
        title: 'Inwards',
        translate: 'Inwards - GRUNGEMOUTH',
        type: 'item',
        icon: 'list',
        url: 'inwardsGrungemouth'
      },
      {
        id: 'Inwards',
        title: 'Inwards',
        translate: 'Inwards - PUNE',
        type: 'item',
        icon: 'list',
        url: 'inwardsPune'
      }
    ]
  },
  {
    id: 'Outwards',
    type: 'section',
    title: 'Outwards',
    translate: 'Outwards',
    icon: 'file',
    children: [
      {
        id: 'Outwards',
        title: 'Outwards',
        translate: 'Outwards - DUISBURG',
        type: 'item',
        icon: 'list',
        url: 'outwardReport'
      },
      {
        id: 'Outwards',
        title: 'Outwards',
        translate: 'Outwards - GRUNGEMOUTH',
        type: 'item',
        icon: 'list',
        url: 'outwardGrungemouth'
      },
      {
        id: 'Outwards',
        title: 'Outwards',
        translate: 'Outwards - PUNE',
        type: 'item',
        icon: 'list',
        url: 'outwardsPune'
      }
    ]
  },
  {
    id: 'Inventory',
    type: 'section',
    title: 'Inventory',
    translate: 'Inventory',
    icon: 'file',
    children: [
      {
        id: 'Inventory',
        title: 'Inventory',
        translate: 'Inventory - DUISBURG',
        type: 'item',
        icon: 'list',
        url: 'inventory'
      },
      {
        id: 'Inventory',
        title: 'Inventory',
        translate: 'Inventory - GRUNGEMOUTH',
        type: 'item',
        icon: 'list',
        url: 'inventoryGrungemouth'
      },
      {
        id: 'Inventory',
        title: 'Inventory',
        translate: 'Inventory - PUNE',
        type: 'item',
        icon: 'list',
        url: 'inventoryPune'
      }
    ]
  },
  {
    id: 'Aging',
    type: 'section',
    title: 'Aging',
    translate: 'Aging',
    icon: 'file',
    children: [
      {
        id: 'Aging',
        title: 'Aging',
        translate: 'Aging - DUISBURG',
        type: 'item',
        icon: 'list',
        url: 'aging'
      },
      {
        id: 'Aging - Grungemouth',
        title: 'Aging - Grungemouth',
        translate: 'Aging - GRUNGEMOUTH',
        type: 'item',
        icon: 'list',
        url: 'agingGrungemouth'
      },
      {
        id: 'Aging - Pune',
        title: 'Aging - Pune',
        translate: 'Aging - PUNE',
        type: 'item',
        icon: 'list',
        url: 'agingPune'
      }
    ]
  }
]
